<template>
  <v-card id="tag-adder" flat class="pt-3 card" tabindex="-1" :ripple="false" :style="dialog ? '' : 'max-height: 38vh;'">
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!hide_actions"
        @click="closeDialog()"
        fab
        small
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div style="width: 20px;"></div>
    </v-row>
    <v-row  v-if="canFilterTypes">
      <v-col cols="12" class="py-0">
        {{$t('analysis.creator')}}
        <div style="color: #bbb; margin-bottom: 3px; font-size: 1.2em" class="my-2 pa-1">
          <v-chip
            label
            :style="`margin: 1px;`"
            class="tag-adder-tag"
            :color="ownClipsOnly ? 'primary' : ''"
            @click="$emit('ownClips')"
            >
            {{ $t('analysis.own_clips')}}
          </v-chip>
        </div>
      </v-col>
      <v-col cols="12" class="py-0">
        {{$t('analysis.type')}}
        <div style="color: #bbb; margin-bottom: 3px; font-size: 1.2em" class="my-2 pa-1">
            <v-chip
              label
              :style="`margin: 1px;;`"
              class="tag-adder-tag"
              :color="types.includes('clips') ? 'primary' : ''"
              @click="$emit('toggleType', 'clips')"
            >
            {{ $t('analysis.clips')}}
          </v-chip>
          <v-chip
            label
            :style="`margin: 1px;`"
            class="tag-adder-tag"
            :color="types.includes('times') ? 'primary' : ''"
            @click="$emit('toggleType', 'times')"
            >
            {{ $t('analysis.times')}}
          </v-chip>
        </div>
      </v-col>
    </v-row>
    <div v-if="hiddenActiveTags.length !== 0" :style="`height: 70px; position: fixed; width: 100%; background-color: inherit; z-index: 3; top: ${topOfAnalysisBar + 150}`">
      <!-- <div>{{ $t('tags.hidden_active_tags') }}</div> -->
      <v-chip
        v-for="tag in hiddenActiveTags"
        :style="`margin: 1px; border: 2px solid ${tag.map_color} !important;`"
        label
        class="tag-adder-tag"
        :id="new Date().valueOf()"
        :name="tag.id"
        :small="chipsize == 'small'"
        :key="tag.id"
        color="primary"
        @click="handleTagClick(tag)"
        @contextmenu.prevent="handleRightClick(tag)"
      >{{ tag.tag_name }}</v-chip>
    </div>
    <div :style="`max-height: calc(100vh - ${tall ? '-400px' : '-400px'}); overflow-y: scroll; position: relative;`" class="hideScroll">
      <div v-if="loading_tags" style="text-align: center;">
        <v-progress-circular
          class="my-10 mx-auto"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-card style="text-align: center;" class="pa-5" flat v-else-if="!loading_tags && !filtered_groups.length">
        <v-card-title style="justify-content: center;">
          {{$t('no_tags')}}
        </v-card-title>
        <v-btn color="primary" @click="$emit('edit_tags')">
          <v-icon>mdi-plus</v-icon>
          {{$t('tags.tags_header')}}
        </v-btn>
      </v-card>
      <div
        v-else
        class="my-2 pa-1"
        v-for="group in filtered_groups"
        :key="group.id"
        :style="`display: flex; flex-direction: row; border-radius: 5px;`"
      >
        <div style="flex-grow: 1;">
          <div v-if="(!onlyGroupChoosable || isAnalysisPage)" style="color: #bbb; margin-bottom: 3px;">
            {{group.group_name}}
          </div>
          <v-chip label color="primary" small class="mb-1 mt-2" v-if="onlyGroupChoosable" @click="$emit('group_clicked', group)">
            {{group.group_name}}
          </v-chip>
          {{group.show_in_join_w_group_tag}}
          <div v-if="(!group.dropdown || isAnalysisPage)">
            <draggable :preventOnFilter="false" @start="dragStart" @end="dragEnd" :options="{disabled: !ticking && group.action_type == 'metadata', forceFallback: true, fallbackTolerance: 10}">
              <v-chip
                :disabled="onlyGroupChoosable"
                :style="`margin: 1px; border: 2px solid ${tag.map_color} !important;`"
                label
                class="tag-adder-tag"
                :id="tag.id"
                :name="tag.id"
                :outlined="group.action_type == 'metadata'"
                :small="chipsize == 'small'"
                @click="handleTagClick(tag)"
                @contextmenu.prevent="handleRightClick(tag)"
                v-for="tag in filterShowingGroupTags(group)"
                :key="tag.id"
                :color="tagIsChosen(tag.id) ? 'primary' : ''"
                style="position: relative;"
                :absolute="true"
              >
                <v-overlay color="#4CAF50" :absolute="true" :value="tag_was_chosen(tag.id)">
                  <v-expand-x-transition>
                    <v-icon style="position: absolute; top: -7px; left: -9px;" class="pl-1" size="15" color="white">
                      mdi-check
                    </v-icon>
                  </v-expand-x-transition>
                </v-overlay>
                {{tag.tag_name}}
                <v-chip class="px-1 ml-1" v-if="tag.hotkey" x-small>
                  {{tag.hotkey}}
                </v-chip>
                <v-chip class="ml-1 meta-tag" @click.stop.prevent="removeMetaTagFromTag({ target_tag_id: tag.id, meta_tag_id: meta_tag.id })" style="cursor: pointer;" x-small :key="'meta_tag' + meta_tag.id" v-for="meta_tag in meta_tags_by_tag_id(tag.id)">
                  {{ meta_tag.tag_name }}
                </v-chip>
                <!-- <div v-if="tagIsChosen(tag.id) && (group.enduring || group.action_type == 'background') && ticking" class="recording-circle ml-3"></div> -->
                <v-progress-linear :value="100" v-if="group.action_type == 'action'" style="position: absolute; top: 0px; left: 0px;"></v-progress-linear>
                <v-progress-linear :value="calculateTagValue(tag)" color="error" v-if="tagIsChosen(tag.id) && group.action_type != 'metadata' && ticking" :indeterminate="(group.enduring || group.action_type == 'background') == 1" style="position: absolute; bottom: 0px; left: 0px;"></v-progress-linear>
              </v-chip>
            </draggable>
            <v-chip
              v-if="addable && !onlyGroupChoosable"
              label
              small
              outlined
              class="ml-2"
              @click="addingToGroup = group"
            >
              <v-icon>mdi-plus</v-icon>
            </v-chip>
          </div>
          <vue-select
            :placeholder="$t('all')"
            label="tag_name"
            @option:selected="handleSelectChange($event.id, group.id)"
            @option:deselected="handleSelectChange([], group.id)"
            v-else-if="!onlyGroupChoosable"
            compact
            :options="group.tags"
            item-value="id"
            item-text="tag_name"
            clearable
            :no-data-text="$t('tags.no tags')"
          >test</vue-select>
        </div>
        <v-btn v-tooltip="`Hide group`" v-if="tagging || ticking" @click="hideGroup(group.id)" text class="mt-4" x-small style="position: relative; right: 0; top: 0px;">
          <v-icon color="grey" size="15">mdi-eye-outline</v-icon>
        </v-btn>
      </div>
      <div
        class="mt-10"
        v-if="hiddenGroupIds.length"
      >
        <v-chip
          x-small
          v-for="id in hiddenGroupIds"
          :key="'hidden' + id"
          @click="unHideGroup(id)"
        >
          {{groupNameById(id)}}
          <v-icon class="ml-3" size="15">mdi-eye-off-outline</v-icon>
        </v-chip>
      </div>
    </div>
    <v-divider></v-divider>
    <v-card-actions v-if="!hideExtraButtons && !hide_actions" absolute>
      <v-btn
        @click="clearDialog()"
      >{{$t('clear')}}</v-btn>
      <!-- <v-btn
        @click="chooseAll()"
      >{{$t('choose_all')}}</v-btn> -->
      <v-spacer></v-spacer>
      <v-btn
        @click="$emit('set'); $emit('close');"
        color="primary"
      >
        {{$t('set')}} (enter)
        <v-chip
          small
          class="ml-3"
        >
          {{value ? value.length : 0}}
        </v-chip>
      </v-btn>
    </v-card-actions>
    <v-btn v-if="addable" color="primary" @click="showEditTags = true" rounded small style="position: absolute; bottom: 10px; right: 10px;">
      Edit tags
    </v-btn>
    <v-dialog scrollable width="1000" v-model="showEditTags">
      <v-card>
        <tags></tags>
      </v-card>
    </v-dialog>
    <v-dialog style="position: relative;" max-width="600" @click:outside="addingToGroup = null" :value="!!addingToGroup">
      <add-tag @tag-added="addingToGroup = null" :group="addingToGroup"></add-tag>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Tags from '@/views/Tags.vue'
import AddTag from './AddTag.vue'
import draggable from 'vuedraggable'

export default {
  props: ['ownClipsOnly', 'types', 'canFilterTypes', 'dialog', 'addable', 'onlyGroupChoosable', 'chipsKey', 'tall', 'dontCloseWithEnter', 'chipsize', 'show', 'value', 'hideExtraButtons', 'groupChoosable', 'filtering', 'tagging', 'isAnalysis', 'closable', 'hide_actions', 'sport_id', 'ticking'],
  mounted() {
      window.addEventListener(['keydown'], () => {
        if(this.keybinds) this.handleSpace()
      });
    // if(!this.tag_groups?.length) {
      if(this.sport_id) {
        this.initSportTags(this.sport_id)
      } else if(!this.isAnalysis) {
        this.initTags(this.currentTeamId)
          .then()
          .catch(e => this.error(e))
      } else {
        this.initLeagueTags(this.leagueId)
          .then()
          .catch(e => this.error(e))
      }
    // }

    if(this.tagging) {
      let str = window.localStorage.getItem('tiimio-hidden-groups')
      if(str) {
        this.hiddenGroupIds = JSON.parse(str)
      }
    }

    window.addEventListener('pointermove', e => {
      this.setLatestPointerPos({ x: e.clientX, y: e.clientY})
    })

    if ('IntersectionObserver' in window) {
      this.intersectionObserver = new IntersectionObserver(
        this.handleIntersection,
        {
          root: document.getElementById('ticks-squares'), // Replace with your scrollable element
          threshold: 0.1, // Adjust threshold as needed
          rootMargin: '0px', // Adjust rootMargin as needed
        }
      )
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleSpace)
  },
  shortcuts: {
    keydown: function (event) {
      if(!this.keybinds) return true
      this.handleHotkeys(event)
    },
    enter: function() {
      if(this.dontCloseWithEnter) return
      this.$emit('set')
      this.$emit('close')
    },
    escape: function() {
      this.$emit('close')
    }
  },
  components: {
    Tags,
    AddTag,
    draggable
  },
  data: () => ({
    hiddenGroupIds: [],
    showEditTags: false,
    addingToGroup: null,
    hiddenActiveTags: [],
    tagIdsAnimationOngoing: []
  }),
  computed: {
    ...mapGetters(['keybinds']),
    ...mapGetters('player', [
      'accurateCurrentTime'
    ]),
    ...mapGetters('ticks', [
      'ongoing_clips',
      'meta_tags_by_tag_id',
      'tag_was_chosen',
      'time_by_channel'
    ]),
    ...mapGetters('tag', [
      'tag_groups',
      'all_tags',
      'loading_tags',
      'group_by_id'
    ]),
    ...mapGetters('videos', [
      'video'
    ]),
    ...mapGetters('user', [
      'currentTeamId',
      'leagueId'
    ]),
    filtered_groups() {
      let groups;

      if(!this.filtering && !this.tagging) groups =  this.tag_groups
      if(this.filtering ) groups =  this.tag_groups.filter(tag_group => tag_group.show_in_filtering)
      groups =  this.tag_groups.filter(tag_group => tag_group.show_in_tagging)
      groups = groups.filter(g => !this.hiddenGroupIds.includes(g.id))

      groups = groups.map(g => {
        return {...g, tags: this.filterShowingGroupTags(g)}
      })
      groups = groups.filter(g => g.tags.length != 0)
      return groups
    },
    isAnalysisPage() {
      return this.$route.path.includes('analysis')
    },
    activeActionTags() {
      let active = []
      for (let tag of this.value) {
        if(this.group_by_id(tag.group_id).action_type == 'action')
        active.push(tag)
      }
      return active
    },
    topOfAnalysisBar() {
      return document.getElementsByClassName('io-analysis-bar')[0].offsetTop
    }
  },
  methods: {
    ...mapActions('tag', [
      'initTags',
      'initLeagueTags',
      'initSportTags'
    ]),
    ...mapActions('noti', [
      'error'
    ]),
    ...mapActions('ticks', [
      'dragStart',
      'dragEnd',
      'handleMouseOver',
      'setLatestPointerPos',
      'removeMetaTagFromTag'
    ]),
    tagAnimation(id) {
      this.tagIdsAnimationOngoing = this.tagIdsAnimationOngoing.concat([id])

      setTimeout(() => {
        var id_remove = id
        this.tagIdsAnimationOngoing = this.tagIdsAnimationOngoing.filter(t => t.id != id_remove)
      })
    },
    tagAnimationGoingOn(id) {
      return this.tagIdsAnimationOngoing.includes(id)
    },
    handleIntersection(entries) {
      entries.forEach(entry => {
      if (entry.isIntersecting) {
        // Element is now visible, you can remove it from the list of hidden elements
        this.removeFromHiddenList(entry.target.id);
      } else {
        // Element is not visible (exiting viewport), you can trigger an action here
        this.handleExitViewport(entry.target.id);
      }
    });
    },
    handleRightClick(tag) {
      this.$emit('tag_right_clicked', tag)
      this.removeFromObservation(document.getElementById(tag.id))
      this.removeFromHiddenListMouse(tag.id)
    },
    removeFromHiddenListMouse(id) {
      this.hiddenActiveTags = this.hiddenActiveTags.filter(tag => tag.id.toString() === id);
    },
    removeFromHiddenList(id) {
      this.hiddenActiveTags = this.hiddenActiveTags.filter(tag => tag.id.toString() != id);
    },
    handleExitViewport(id) {
      const matchingObject = this.activeActionTags.find(item => item.id.toString() === id);

      if (matchingObject) {
        this.hiddenActiveTags.push(matchingObject);
      }
    },
    removeFromObservation(element) {
      if (this.intersectionObserver) {
        this.intersectionObserver.unobserve(element);
      }
    },
    addToObservation(element) {
      if(this.intersectionObserver) {
        this.intersectionObserver.observe(element)
      }
    },
    handleSpace() {
      // if(event.key === ' ' && this.keybinds) {
      //   alert('kea')
      //   if (document.activeElement && this.$route.query?.sidebar === 'tagging') {
      //       document.activeElement.blur();
      //       event.preventDefault();
      //   }
      // }
    },
    calculateTagValue(tag) {
      const ongoing = this.ongoing_clips.find(o => o.main_tag.id == tag.id)
      if(!ongoing) return

      const start = ongoing.starttime
      const end = ongoing.will_end
      return ((this.accurateCurrentTime || this.time_by_channel) - start) / (end - start) * 100
    },
    handleHotkeys(e) {
      let key = e.key;
      if(e.shiftKey) {
        if (e.keyCode >= 48 && e.keyCode <= 57) {
            key = String.fromCharCode(e.keyCode);
        }
        key = key.toLowerCase()
      }

      this.all_tags.forEach(tag => {
        if(e.shiftKey && tag.hotkey === key) {
          e.preventDefault()
          this.$emit('tag_right_clicked', tag)
          this.removeFromObservation(document.getElementById(tag.id))
          this.removeFromHiddenList(tag.id)
          return
        }
        if(tag.hotkey === key) {
          this.handleTagClick(tag)
        }
      })
    },
    groupNameById(id) {
      return this.tag_groups.find(g => g.id == id)?.group_name
    },
    originalGroupIdWithOriginalId(id) {
      return this.tag_groups.map(g => g.tags).flat().find(t => t.id == id).group_id
    },
    handleSelectChange(id, group_id) {
      this.$emit('input', this.value.filter(t => t.group_id != group_id))
      this.handleTagClick(this.all_tags.find(t => t.id == id))
    },
    filterShowingGroupTags(group) {
      // if(!this.isAnalysisPage || !this.isAnalysis) return group.tags
      if(!this.value) return group.tags.filter(t => t.archived == false)
      try {
        if(typeof group.show_in_join_w_group_tags == 'string') group.show_in_join_w_group_tags = JSON.parse(group.show_in_join_w_group_tags)
      } catch {
        return group.tags.filter(t => t.archived == false)
      }

      if(!group.show_in_join_w_group_tags || !group.show_in_join_w_group_tags.length || !group.show_in_join_w_group_tags[0]) return group.tags.filter(t => t.archived == false)

      let all_groups_with_same_name_ids = []
      group.show_in_join_w_group_tags.forEach(show_in_join_w_group_tag => {
        // show_in_join_w_group_tag is the group that tells this group what tags to show

        // lets get the whole group
        let join_group = this.tag_groups.find(g => g.id == show_in_join_w_group_tag)
        let join_group_chosen_tag = this.value.find(t => t.group_id == join_group.id)

        if(!join_group_chosen_tag) return []

        let tag_name = join_group_chosen_tag.tag_name || join_group_chosen_tag.name
        let group_w_said_name = this.tag_groups.find(g => g.group_name == tag_name)

        all_groups_with_same_name_ids = all_groups_with_same_name_ids.concat(group_w_said_name.id)
      })

      if(!all_groups_with_same_name_ids.length) return group.tags.filter(t => t.archived == false)
      return group.tags.filter(t => all_groups_with_same_name_ids.includes(this.originalGroupIdWithOriginalId(t.original_id) && group.tags.filter(t => t.archived == false)))
    },
    hideGroup(id) {
      this.hiddenGroupIds = this.hiddenGroupIds.concat(id)

      window.localStorage.setItem('tiimio-hidden-groups', JSON.stringify(this.hiddenGroupIds))
    },
    unHideGroup(id) {
      this.hiddenGroupIds = this.hiddenGroupIds.filter(i => i != id)

      window.localStorage.setItem('tiimio-hidden-groups', JSON.stringify(this.hiddenGroupIds))
    },
    tagIsChosen(id) {
      if(!this.value) return false
      return this.value.map(t => t.id).includes(id)
    },
    clearDialog() {
      this.$emit('input', [])
    },
    chooseAll() {
      const group_keys = Object.keys(this.tag_groups)
      let tags = []

      group_keys.forEach(key => {
        tags = tags.concat(this.tag_groups[key]['tags'])
      })

      this.$emit('input', tags)
    },
    closeDialog() {
      this.$emit('close')
    },
    // handleInput ilmeisesti käyttämätön
    handleInput(e) {
      this.$emit('input', e)
    },
    handleTagClick(tag) {
      this.$nextTick(() => {
        this.$emit('tag_clicked', tag)
      })

      if(this.value) {
        if(this.tagIsChosen(tag.id)) {
          // unchoose tag
          this.$emit('input', [...this.value].filter(t => t.id !== tag.id))
          this.removeFromObservation(document.getElementById(tag.id))
          this.removeFromHiddenList(tag.id)
        }
        else {
          // choose tag
          this.addToObservation(document.getElementById(tag.id))
          const group = this.tag_groups.find(g => g.id == tag.group_id)
          if(group.one_tag_only && !this.filtering) {
            // only one tag
            this.$emit('input', [...this.value.filter(t => t.group_id != group.id)].concat(tag) )

            return
          }

          this.$emit('input', [...this.value].concat(tag))
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card::before {
  display: none;
}

.meta-tag {
  &:hover {
    border: 1px solid black;
  }
}

.recording-circle {
  background-color: red;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  animation: ease pulse 2s infinite;
  margin-right: 0.25em;
}

@keyframes pulse {
  0% {
    background-color: red;
  }
  50% {
    background-color: #f06c6c;
  }
  100% {
    background-color: red;
  }
}
</style>