<template>
  <div style="width: 100%; height: 100%; overflow-y: scroll; padding: 50px 0 80px 0;">
    <v-dialog @click:outside="editingGroup = null;" max-width="800" v-model="show_create_group">
      <v-card>
        <CreateGroup 
          :sport="sport" 
          v-if="show_create_group" 
          :editing_group="editingGroup" 
          @close="show_create_group = false; 
          editingGroup = null;"
          @createGroup="submitTagGroup($event)"
        ></CreateGroup>
      </v-card>
    </v-dialog>
    <div style="max-width: 1200px;" class="mx-auto"  flat>
      <v-card-title>
        <div
          v-if="!select" 
          class="my-3 text-h3"
        >{{ showingArchived ? $t('tags.archived_header') : $t('tags.tags_header')}}</div>
        <v-card-title v-else>Tags</v-card-title>
        <v-tooltip v-if="!groups_only_ids && !showingArchived" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="show_create_group = true"
              color="primary"
              class="ml-5"
              fab
              :text="select"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon v-if="menu != 'group'">mdi-plus</v-icon>
              <v-icon v-else>mdi-minus</v-icon>
            </v-btn>
          </template>
          <span v-if="menu != 'group'">{{ $t('tags.add_tag_group') }}</span>
          <span v-else>{{ $t('tags.close') }}</span>
          
        </v-tooltip>
        <v-btn 
          x-small
          style="margin-left: auto; height: 3em" 
          @click="reloadTags"
          outlined
        >
          <div v-if="!showingArchived">
            {{$t('tags.show_archived_tags')}}
          </div>
          <div v-else>
            {{$t('tags.active_tags')}}
          </div>
        </v-btn>
      </v-card-title>
      <div class="text-p pl-5">
        {{ $t('tags_subtext') }}
      </div>











      <!-- ADD TAG GROUP -->
      <v-card-text>
        <v-expand-transition>
          <v-card
            v-if="menu == 'group' && !showingArchived"
            max-width="500"
            class="mb-3"
            :loading="saving_tag == 'group'"
          >
            <v-card-text>
              <v-card-subtitle
                style="display: flex; flex-direction: row;"
              >
                {{ $t('tags.new_group') }}
                <v-spacer></v-spacer>
                <v-btn 
                  x-small 
                  rounded
                  @click="showMirrors = !showMirrors"
                >{{$t('tags.mirrors')}}</v-btn>
              </v-card-subtitle>
              <v-form @submit="submitTagGroup($event)">
                <v-text-field
                  v-prevent-keys
                  dense
                  solo
                  outlined
                  :placeholder="$t('tags.tag_group_name')"
                  autofocus
                  v-model="tag_group_name"
                  :persistent-placeholder="true"
                >
                </v-text-field>
                <div>
                  <v-select
                    solo
                    :label="$t('tag_group_type')"
                    v-model="action_type"
                    :items="action_type_values"
                    attach
                  ></v-select>
                </div>
                <v-switch v-model="enduring" v-if="action_type == 'action'" :label="$t('stays_on')"></v-switch>
                <v-row v-if="action_type == 'action' && !enduring" class="mb-5">
                  <v-col style="text-align: center;" cols="6">
                    <RangeSelector :min="-1000" :max="0" :title="$t('start')" v-model="buffer_start"></RangeSelector>
                  </v-col>
                  <v-col style="text-align: center;" cols="6">
                    <RangeSelector :min="1" :max="1000" :title="$t('end')" v-model="buffer_end"></RangeSelector>
                  </v-col>
                </v-row>
                <div v-if="showMirrors">
                  <v-select
                    class="mt-4"
                    v-model="mirrors"
                    :items="groupsAvailableForMirroring"
                    attach
                    dense
                    x-small
                    item-value="key"
                    clearable
                    solo
                    chips
                    :label="$t('tags.mirrors_dropdown')"
                    multiple
                  ></v-select>
                  <v-icon>
                    mdi-question
                  </v-icon>
                </div>
                <div>
                  <v-switch
                    v-model="tag_group_one_only"
                    :label="$t('tags.one_tag_only')"
                  ></v-switch>
                </div>
                <v-btn
                  x-small
                  rounded
                  :color="saved == 'group' ? 'green' : 'primary'"
                  type="submit"
                >
                  <span v-if="saved != 'group'">{{$t('tags.add_tag_group')}} (Enter)</span>
                  <v-icon v-else color="white">mdi-check</v-icon>
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-expand-transition>
      </v-card-text>





      <!-- GROUPS -->
      <v-card-text v-if="!loading && filteredGroups.length">
        <draggable
          :value="filteredGroups"
          @input="updateGroupOrder($event)"
          handle=".tag-handle"
          @start="dragging_group = true"
          @end="dragging_group = false"
        >
          <v-card :outlined="showingArchived" style="display: flex;" class="mt-4" v-for="(group, group_index) in filteredGroups" :key="group.id">
            <div style="position: absolute; bottom: 10px; right: 10px; color: grey;" v-if="user.tiimio_admin">
              {{ group.id }}
            </div>
            <div style="flex-grow: 1;">
              <v-card-title>
                <v-form 
                  @submit="rename_group($event)"
                  v-if="renaming_group && renaming_group.id == group.id"
                >
                  <v-text-field 
                    v-model="renaming_group.group_name"
                    append-icon="mdi-check"
                    style="max-width: 300px;"
                    @click:append="rename_group()"
                    autofocus
                  >
                  </v-text-field>
                </v-form>
                <span v-else-if="!select" @dblclick="renaming_group = group" :style="cardTitleColor">
                  {{group.group_name}}
                </span>
                <v-card-subtitle v-else link>
                  {{group.group_name}}
                </v-card-subtitle>

                <v-tooltip v-if="!showingArchived" bottom>
                  <template v-slot:activator="{ attrs }">
                    <v-btn
                      @click="addingTagToGroup = group"
                      v-if="!group.mirrors || !group.mirrors.length"
                      class="ml-3"
                      fab
                      x-small
                      :text="select"
                      v-bind="attrs"
                      :ref="'activator' + group.id"
                    >
                      <v-icon v-if="menu != group.id">mdi-plus</v-icon>
                      <v-icon v-else>mdi-minus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('tags.add_tag') }}</span>
                </v-tooltip>
                <span
                  v-if="group.mirrors && group.mirrors.length && !dragging_group"
                >
                  <span 
                    style="font-size: 10px;"
                    class="ml-4"
                  >
                    {{$t('tags.mirrors_dropdown')}}
                  </span>
                  <v-chip
                    class="mt-1" 
                    small
                    v-for="mirror in group.mirrors"
                    :key="mirror.id + 'mirror'"
                  >{{mirror.group_name}}</v-chip>
                </span>
                <v-spacer></v-spacer>
              <div v-if="editableGroup(group)">
                <v-chip v-if="group.sport_id && !dragging_group" x-small class="mr-2">{{ $t('tags.ready_made') }}</v-chip>
                <v-chip class="mr-2" x-small v-if="group.enduring && group.action_type == 'action'">
                  Stays on
                </v-chip>
                <v-chip x-small class="mr-2" v-else-if="group.action_type != 'metadata'">
                  {{ group.buffer_start }} | 
                  {{ group.buffer_end }}
                </v-chip>
                <v-chip class="mr-2" x-small v-if="group.one_tag_only">
                  Max 1
                </v-chip>
                <!-- <v-chip
                  x-small
                  :color="group.show_in_filtering ? 'primary' : ''"
                  @click="updateGroupShowInFiltering({id: group.id, show_in_filtering: !group.show_in_filtering})"
                >
                  Filter
                </v-chip> -->
                <v-chip x-small :color="group.action_type == 'action' ? 'primary' : 'metadata'">
                  {{ group.action_type == 'action' ? $t('clips.clip') : $t('clips.metadata') }}
                </v-chip>
                <v-menu offset-y left v-if="!select">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      fab
                      class="px-0 ml-3"
                      text
                      v-bind="attrs"
                      v-on="on"
                      v-if="!group.sport_id || isAdmin"
                    >
                      <v-icon size="20">
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item 
                      v-if="!showingArchived"
                      @click="editGroup(group)" 
                      link
                      dense
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-cog</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ $t('tags.edit_group_settings') }}</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="!showingArchived"></v-divider>
                    <v-list-item 
                      @click="startArchivingGroup(group.id)" 
                      link
                      dense
                    >
                      <v-list-item-icon>
                        <v-icon v-if="!showingArchived">mdi-archive-arrow-down-outline</v-icon>
                        <v-icon v-else>mdi-archive-arrow-up-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title v-if="!showingArchived">{{ $t('tags.archive_group') }}</v-list-item-title>
                      <v-list-item-title v-else>{{ $t('tags.activate_group') }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              </v-card-title>
              <v-card-text>
                <v-chip-group
                  @change="handleChipGroupChange($event)"
                  v-model="chosen[group_index]"
                  column
                  :multiple="select"
                  :key="group.id"
                  active-class="primary--text"
                  v-show="!dragging_group"
                >
                <draggable
                  :value="group.tags"
                  v-bind="dragOptions"
                  @input="groupOrderUpdate($event, group.id)"
                >
                  <transition-group v-if="!group.mirrors.length || filteredTagsLength(group) < 6" name="list" tag="div">
                    <v-chip
                      outlined
                      @click.prevent
                      @dblclick="renaming_tag = !select ? tag : null"
                      :filter="select"
                      class="ml-1 mt-1"
                      v-for="tag in filteredTags(group)"
                      :key="group.id.toString() + tag.id.toString()"
                      :disabled="group.mirrors && group.mirrors.length > 0"
                      :style="{border: `2px solid ${tag.map_color}`}"
                    >
                      <v-form
                        @submit="rename_tag($event)"
                        v-if="renaming_tag && renaming_tag.id == tag.id"
                      >
                        <v-text-field
                          autofocus
                          style="margin-bottom: -10px;"
                          dense
                          v-prevent-keys
                          v-model="renaming_tag.tag_name"
                          append-icon="mdi-check"
                          @click:append="rename_tag()"
                        >
                        </v-text-field>
                      </v-form>
                      <span v-else>
                        <v-icon v-if="!tag.team_id && group.sport_id">mdi-circle-small</v-icon>
                        {{ tag.tag_name }}
                        <v-chip class="ml-2" v-if="tag.hotkey" x-small>
                          {{tag.hotkey}}
                        </v-chip>
                      </span>
                      <v-menu offset-y v-if="!select && (tag.team_id || !group.sport_id || isAdmin)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            x-small
                            fab
                            v-if="!group.mirrors || !group.mirrors.length"
                            style="width: 20px; height: 30px;"
                            class="px-0 ml-3"
                            text
                            v-bind="attrs"
                            v-on="on"
    
                          >
                            <v-icon size="20">
                              mdi-dots-vertical
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item v-if="!showingArchived" @click="renaming_tag = tag" link>
                            <v-list-item-icon>
                              <v-icon>mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('tags.rename') }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item v-if="!showingArchived" @click="editingTagColor = true; editingTagColorChosen = tag.map_color || '#222'; editingTagColorId = tag.id" link>
                            <v-list-item-icon>
                              <v-icon>mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title color="red">{{ $t('tags.edit_map_color') }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item v-if="!showingArchived" @click="editingTagHotkey = true; editingTagHotkeyChosen = tag.hotkey; editingTagHotkeyId = tag.id" link>
                            <v-list-item-icon>
                              <v-icon>mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title color="red">{{ $t('tags.edit_hotkey') }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item v-if="!showingArchived && group.action_type != 'action'" @click.stop="">
                            <v-switch @change="changeKeepChosen(tag, $event)" :label="$t('keep_chosen')" :input-value="tag.keep_chosen"></v-switch>
                          </v-list-item>
                          <v-divider v-if="!showingArchived"></v-divider>
                          <v-list-item @click="startArchivingTag(tag.id)" link>
                            <v-list-item-icon>
                              <v-icon v-if="!showingArchived">mdi-archive-arrow-down-outline</v-icon>
                              <v-icon v-else>mdi-archive-arrow-up-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title color="red" v-if="!showingArchived">{{ $t('tags.archive') }}</v-list-item-title>
                            <v-list-item-title color="red" v-else>{{ $t('tags.activate') }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            {{ tag.id }}
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-chip>
                  </transition-group>
                  <v-chip
                    v-else
                    outlined
                  >
                    {{ filteredTagsLength(group) }} tags mirrored
                  </v-chip>
                </draggable>
                </v-chip-group>
              </v-card-text>
            </div>
            <div class="tag-handle" style="cursor: pointer; display: flex; justify-content: center; flex-direction: column;">
              <v-icon color="grey" style="align-self: center;">
                mdi-dots-grid
              </v-icon>
            </div>
          </v-card>
        </draggable>
      </v-card-text>
      <div v-else-if="loading" style="text-align: center; justify-content: center; margin-top: 5em;">
          <v-progress-circular
          class=""
          indeterminate
          color="primary"></v-progress-circular>
      </div>
      <v-card v-else-if="!loading && !all_tags" max-width="800" class="mx-auto pa-10" style="text-align: center;">
        <v-card-title style="justify-content: center;">
          {{$t('no_tags')}}!
        </v-card-title>
        <v-card-text>
          {{ $t('no_tags_long') }}
        </v-card-text>
        <v-btn class="mt-4" color="primary" @click="menu = 'group'">
          {{ $t('tags.add_tag_group') }}
          <v-icon class="ml-3">mdi-plus</v-icon>
        </v-btn>
      </v-card>
      <v-card v-else-if="!loading && !filteredGroups.length && !showingArchived" max-width="800" class="mx-auto pa-10" style="text-align: center;">
        <v-card-title style="justify-content: center;">
          {{$t('tags.no_active_tags')}}!
        </v-card-title>
        <v-card-text>
          {{ $t('tags.no_active_tags_long') }}
        </v-card-text>
      </v-card>
      <v-card v-else-if="!loading && !filteredGroups.length && showingArchived" max-width="800" class="mx-auto pa-10" style="text-align: center;">
        <v-card-title style="justify-content: center;">
          {{$t('tags.no_archived_tags')}}!
        </v-card-title>
        <v-card-text>
          {{ $t('tags.no_archived_tags_long') }}
        </v-card-text>
      </v-card>

    </div>
    <ConfirmationDialog
      :show="showDialog"
      :text.sync="dialog.dialogText"
      :btnText.sync="dialog.btnText"
      :btnColor.sync="dialog.btnColor"
      :subText.sync="dialog.subText"
      @accept="accept()"
      @decline="decline()"
    ></ConfirmationDialog>
    <GroupSettings
      @close="editingGroup = null"
      :group="editingGroup"
      @edited="editingGroup = $event"
      :show="false"
    ></GroupSettings>
    <v-dialog
      max-width="800"
      v-model="editingTagColor"
    >
      <v-card>
        <div :key="editingTagColorChosen" :style="`height: 30px; width: 100%; background-color: ${editingTagColorChosen ? editingTagColorChosen : ''}; border: 1px solid black;`">

        </div>
        <v-color-picker
          hide-sliders
          hide-inputs 
          hide-canvas 
          width="800"
          show-swatches
          mode="hex"
          :value="editingTagColorChosen || '#FF0000'"
          @input="editingTagColorChosen = $event"
        ></v-color-picker>
        <v-card-actions>
          <v-btn @click="editingTagColor = false">
            {{$t('cancel')}}
          </v-btn>
          <v-btn @click="editingTagColorChosen = null">
            {{$t('tags.clear_color')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="saveNewTagColor()" color="primary">
            {{$t('save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editingTagHotkey"
      max-width="400"
    >
      <v-card>
        <v-card-subtitle>
          <v-text-field
            :placeholder="$t('tags.hotkey_placeholder')"
            v-prevent-keys
            v-model="editingTagHotkeyChosen"
            @input="handleHotkeyInput($event)"
          ></v-text-field>
          <div v-if="!hotKeyValid" style="color: red;">{{$t('tags.hotkey_error') }}</div>
        </v-card-subtitle>
        <v-card-actions>
          <v-btn @click="editingTagHotkey = false">
            {{$t('cancel')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="!hotKeyValid || editingTagHotkeyChosen == ''" @click="saveNewTagHotkey()" color="primary">
            {{$t('save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      @click:outside="addingTagToGroup = null" 
      max-width="800" 
      :value="addingTagToGroup"
    >
      <add-tag
        @close="addingTagToGroup = null"
        :group="addingTagToGroup"
      ></add-tag>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import CreateGroup from '@/components/tag/CreateGroup'
import GroupSettings from '@/components/tag/GroupSettings'
import AddTag from '@/components/tag/AddTag'
import RangeSelector from '@/components/tag/RangeSelector'
import draggable from 'vuedraggable'

export default {
  props: ['select', 'value', 'sport', 'groups_only_ids'],
  components: { CreateGroup, RangeSelector, ConfirmationDialog, draggable, GroupSettings, AddTag },
  created() {
    this.loading = true
    if(!this.isAdmin) {
      this.initTags().finally(() => { this.loading = false })
    } else if(!this.sport) {
      this.initLeagueTags(this.leagueId).finally(() => { this.loading = false })
    } else {
      this.initSportTags(this.sportId).finally(() => { this.loading = false })
    }
  },
  data: () => ({
    show_create_group: false,
    dragging_group: false,
    addingTagToGroup: null,
    tag_name: '',
    tag_map_color: '#000',
    editingColor: false,
    tag_group_name: '',
    action_type: 'action',
    buffer_start: -2,
    buffer_end: 5,
    enduring: false,
    tag_group_one_only: true,
    menu: null,
    saving_tag: null,
    saved: null,
    chosen: [],
    renaming_group: null,
    renaming_tag: null,
    showDialog: false,
    showMirrors: false,
    editingGroup: null,
    editingTagColor: false,
    editingTagColorChosen: '#000000',
    editingTagHotkey: false,
    editingTagHotkeyChosen: '',
    editingTagHotkeyId: null,
    dialog: {
      dialogText: '',
      btnText: '',
      btnColor: '',
      subText: ''
    },
    deletingTagId: null,
    archivingGroupId: null,
    deleting: false,
    mirrors: null,
    dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
    },
    action_type_values: [
      { value: "action", text: "Action" },
      { value: "metadata", text: "Metadata" }
    ],
    loading: false
  }),
  methods: {
    ...mapActions('tag', [
      'initTags',
      'initLeagueTags',
      'initSportTags',
      'addTag',
      'addTagGroup',
      'updateGroupShowInFiltering',
      'archiveTag',
      'updateGroupOrder',
      'archiveGroup',
      'updateGroupName',
      'updateTagName',
      'updateTagOrder',
      'updateTagColor',
      'updateTagHotkey',
      'showArchived',
      'updateTag'
    ]),
    ...mapActions('noti', [
      'showSnackbar',
      'error',
      'info'
    ]),
    editGroup(group) {
      this.editingGroup = group; 
      this.$nextTick(() => {
        this.show_create_group = true;
      })
    },
    reloadTags() {
      this.loading = true
      this.showArchived();
      this.initTags().finally(() => {this.loading = false})
    },
    clear() {
      this.chosen = []
    },
    async changeKeepChosen(tag, val) {
      tag['keep_chosen'] = val == true
      await this.updateTag(tag)
    },
    accept() {
      if(!this.archivingTagId && !this.archivingGroupId) this.decline()
      if(this.archivingTagId && !this.showingArchived) this.archiveTagMethod(true)
      else if(this.archivingTagId && this.showingArchived) this.archiveTagMethod(false)
      else if(this.archivingGroupId && !this.showingArchived) this.archiveGroupMethod(true)
      else if(this.archivingGroupId && this.showingArchived) this.archiveGroupMethod(false)
    },
    decline() {
      this.showDialog = false
      this.archivingTagId = null
      this.archivingGroupId = null
    },
    startArchivingTag(id) {
      this.archivingTagId = id
      if (!this.showingArchived) {
        this.dialog = {
          dialogText: this.$t('tags.archive_tag') + '?',
          btnText: this.$t('tags.archive'),
          btnColor: 'red',
          subText: this.$t('tags.archive_subtext')
        }
      } else {
        this.dialog = {
          dialogText: this.$t('tags.activate_tag') + '?',
          btnText: this.$t('tags.activate'),
          btnColor: 'green',
          subText: this.$t('tags.activate_subtext')
      }
      }
    this.showDialog = true
  },
  startArchivingGroup(id) {
    const group = this.group_by_id(id);
    const isMirror = group.mirrors.length !== 0;
    const parent = isMirror ? this.group_by_id(group.mirrors[0].id) : null;

    if(this.showingArchived && isMirror && !parent.tags.every(t => t.archived == 0)) {
      this.dialog = {
        dialogText: this.$t('tags.cannot_activate'),
        btnText: 'OK',
        btnColor: 'green',
        subText: this.$t('tags.cannot_activate_subtext')
      }
    } else {
    this.archivingGroupId = id;
    this.dialog = {
        dialogText: this.$t( !this.showingArchived ? 'tags.archive_group' : 'tags.activate_group') + '?',
        btnText: this.$t( !this.showingArchived ? 'tags.archive' : 'tags.activate'),
        btnColor:  !this.showingArchived ? 'red' : 'green',
        subText: this.$t( !this.showingArchived ? 'tags.archive_group_subtext' : 'tags.activate_group_subtext')
      };
    }
      this.showDialog = true
    },
    archiveTagMethod(trueOrFalse) {
      this.archiveTag({
        tag_id: this.archivingTagId, 
        trueOrFalse: trueOrFalse
      })
        .then(() => {
          let infoText;
          if(this.showingArchived) infoText = this.$t('tags.tag_activated')
          else infoText = this.$t('tags.tag_archived')
          this.showSnackbar({
            text: infoText,
            type: 'info',
            duration: 3
          })
          this.decline()
        })
        .catch(err => {
          this.error(err)
        })
    },
    archiveGroupMethod(trueOrFalse) {
      this.archiveGroup({
        group_id: this.archivingGroupId, 
        trueOrFalse: trueOrFalse})
        .then(() => {
          let infoText;
          if(this.showingArchived) infoText = this.$t('tags.group_activated')
          else infoText = this.$t('tags.group_archived')
          this.showSnackbar({
            text: infoText,
            duration: 2,
            type: 'info'
          })
          this.decline()
        })
        .catch(err => {
          this.error(err)
        })
    },
    saveNewTagColor() {
      this.updateTagColor({
        map_color: this.editingTagColorChosen,
        id: this.editingTagColorId
      })
        .then(() => {
          this.info(this.$t('tags.tag_color_saved'))
          this.editingTagColor = false
        })
    },
    saveNewTagHotkey() {
      this.updateTagHotkey({
        hotkey: this.editingTagHotkeyChosen,
        id: this.editingTagHotkeyId
      })
        .then(() => {
          this.info(this.$t('tags.tag_hotkey_saved'))
          this.editingTagHotkey = false
          this.editingTagHotkeyChosen = ''
        })
    },
    rename_group(e) {
      if(e) e.preventDefault()
      this.updateGroupName({
        id: this.renaming_group.id,
        group_name: this.renaming_group.group_name 
      })
      .then(() => {
        this.renaming_group = null
        this.showSnackbar({
          text: this.$t('tags.group_name_updated'),
          duration: 3,
          type: 'info'
        })
      })
      .catch(e => console.log(e))
    },
    rename_tag(e) {
      if(e) e.preventDefault()
      this.updateTagName({
        id: this.renaming_tag.id,
        tag_name: this.renaming_tag.tag_name 
      })
      .then(() => {
        this.renaming_tag = null
        this.showSnackbar({
          text: this.$t('tags.tag_name_updated'),
          duration: 3,
          type: 'info'
        })
      })
      .catch(e => console.log(e))
    },
    submitTagGroup(event) {
        const { tag_group_name, tag_group_one_only, mirrors, buffer_start, buffer_end, action_type, enduring } = event

        if(!tag_group_name) return
        if(this.saving_tag) return

        this.saving_tag = 'group'
        this.addTagGroup({
          group_name: tag_group_name,
          team_id: this.currentTeamId,
          league_id: this.leagueId,
          sport_id: this.sportId,
          one_tag_only: tag_group_one_only,
          mirrors,
          buffer_start,
          buffer_end,
          action_type,
          enduring,
          immutable: !!(this.sportId || this.leagueId)
        })
        .then(() => {
          this.tag_group_name = ''
          this.saved = 'group'
          this.menu = null
          this.mirrors = false
          this.buffer_start = -2
          this.buffer_end = 5
          this.action_type = 'action'
          this.tag_group_one_only = true
          setTimeout(() => { 
            this.saved = null
          }, 1000)
        })
        .catch(e => {
          this.error(e)
        })
        .finally(() => {
          this.saving_tag = null
        })
      },
      handleChipGroupChange() {
        const allTags = []
        this.chosen.forEach((group, group_index) => {
          if(!group) return

          group.forEach((tag) => {
            allTags.push( this.tag_groups[group_index]['tags'][tag] )
          })
        })

        this.$emit('input', allTags)
      },
      groupOrderUpdate(tags, group_id) {
        this.updateTagOrder({tags: tags.map(t => t.id), group_id})
          .then(() => {
            this.initTags()
          })
      },
      filteredTags(group) {
        if(this.showingArchived) return group.tags.filter(t => t.archived == true)
        // return group.tags.filter(t => t.archived == false  );
        return group.tags.filter(t => !t.archived);
      },
      filteredTagsLength(group) {
        if(this.showingArchived) {
          let filteredTags = group.tags.filter(t => t.archived == true)
          return filteredTags.length
        }
        else {
          let filteredTags = group.tags.filter(t => t.archived == false)
          return filteredTags.length
        }
      },
      editableGroup(group) {
        return this.showingArchived ? group.archived === 1 : true
      },
      handleHotkeyInput(e) {
        let char = e[e.length - 1]
        this.editingTagHotkeyChosen = char
    },
  },
  computed: {
    ...mapGetters('tag', [
      'all_tags',
      'tag_groups',
      'showingArchived',
      'group_by_id',
      'tag_by_id'
    ]),
    ...mapGetters('user', [
      'currentTeamId',
      'user'
    ]),
    mirrorsName() {
      return this.tag_groups.find(g => g.id == this.mirrors)?.group_name
    },
    groupsAvailableForMirroring() {
      return this.tag_groups
        .filter(group => !group.mirrors || !group.mirrors.length)
        .map(group => { 
          return {key: group.id, text: group.group_name}
        })
    },
    isAdmin() {
      return this.$route.path.includes('tiimi-admin')
    },
    leagueId() {
      if(!this.sport) return this.$route.params.league_id
      return null    
    },
    sportId() {
      if(this.sport) return this.$route.params.sport_id
      return null
    },
    available_tag_groups() {
      if(this.groups_only_ids) return this.tag_groups.filter(g => this.groups_only_ids.includes(g.id))
      else return this.tag_groups
    },
    filteredGroups() {
      if (this.showingArchived) {
        let groups = this.available_tag_groups.filter(group => {
          return group.tags.some(tag => tag.archived) || group.archived == 1
        });

        return groups;
      } 

      return this.available_tag_groups.filter(group => !group.archived)
    },
    cardTitleColor() {
      if(this.$vuetify.theme.dark) return this.showingArchived ? 'color: #d4d4d4' : '';
      return this.showingArchived ? 'color: #545353' : ''
    },
    hotKeyValid() {
      if(!this.editingTagHotkeyChosen) return true
      if(this.editingTagHotkeyChosen == '') {
        console.log('k')
        return true
      }
      if(this.all_tags.some(tag => tag.hotkey == this.editingTagHotkeyChosen.toLowerCase())) return false
      if (this.editingTagHotkeyChosen != this.editingTagHotkeyChosen.toLowerCase()) return false
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
// .list-move, /* apply transition to moving elements */
//.list-enter-active,
//.list-leave-active {
//  transition: all 0.5s ease;
//}
//
//.list-enter-from,
//.list-leave-to {
//  opacity: 0;
//  transform: translateX(30px);
//}
//
///* ensure leaving items are taken out of layout flow so that moving
//   animations can be calculated correctly. */
//.list-leave-active {
//  position: absolute;
//}
//
//.tag-handle {
//  &:hover {
//    color: black;
//  }
//}
</style>